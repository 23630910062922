import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ApprovalState, TimecardWeek, TimecardErrors } from '../data/data.model';
import { TimecardErrorsService } from '../data/timecard-errors.service';

@Component({
  selector: 'app-employee-status',
  templateUrl: './employee-status.component.html',
  styleUrls: ['./employee-status.component.css']
})
export class EmployeeStatusComponent implements OnInit, OnChanges {
    @Input() timecardWeek: TimecardWeek;
    @Input() employeeState: ApprovalState;
    @Input() showTimecardIssues: boolean;
    @Output() hasErrors = new EventEmitter<boolean>();

   timecardErrors: TimecardErrors;


    constructor(
        private timecardErrorsService: TimecardErrorsService
    ) { }

    // when the @inputs change, fires below. Go through and get latest values and then update status.
    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {

            const chng = changes[propName];

            if (propName === 'timecardWeek') {
                this.timecardWeek = chng.currentValue;
            }
            if (propName === 'employeeState') {
                this.employeeState = chng.currentValue;
            }
        }
    }

        this.getTimeCardErrors();

    }


    getTimeCardErrors(): void {
        // heavy lifting of what is correct timecard week occurs on the server.

// console.log('getTimecardErrors', this.employeeState.EmployeeNumber, this.timecardWeek.TimeCardStart);

        this.timecardErrorsService
            .getTimecardErrors(this.employeeState.EmployeeNumber, this.timecardWeek)
            .then(errors => this.timecardErrors = errors)
            .then(errors => this.hasErrors.emit(errors.Errors.length > 5))
            .then(errors => console.log('got getTimecardErrors', errors));
    }


  ngOnInit() {
   // this.showTimecardIssues = true;
  }

}
