import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { IHourlyDay } from './data.model';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class HourlyDayService {

    constructor(private http: HttpClient) { }

    getHourlyDay(timecardDay: Date, employeeID: number): Promise<IHourlyDay> {

        const datePipe: any = new DatePipe('en-US');
        const tcsDateStr: string = datePipe.transform(timecardDay, 'MM/dd/yyyy'); // 11/10/2016

        return this.http.get(environment.REST_API + 'DailyHourlyDetail/' + employeeID + '?timecardDay=' + tcsDateStr)
            .toPromise()
            .then(response => response as IHourlyDay)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
