import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ApprovalState, TimecardWeek } from '../data/data.model';

@Component({
  selector: 'app-project-hours-sum',
  templateUrl: './project-hours-sum.component.html',
  styleUrls: ['./hourly-hours.component.css']
})
export class ProjectHoursSumComponent implements OnInit, OnChanges {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;

  WeekTotal = 0;

  constructor() { }

  ngOnInit() {
  }
  // when the @inputs change, fires below. Go through and get latest values and then update status.
  ngOnChanges(changes: SimpleChanges) {
    this.WeekTotal = 0;
  }

  onCalcProjectDayTotal(event: number) {
    this.WeekTotal += event;
    console.log('CalcProjectTotal', event);

  }
}
