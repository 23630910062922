import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApprovalState, SignOffStats } from '../data/data.model';

@Component({
  selector: 'app-sign-off-stats',
  templateUrl: './sign-off-stats.component.html',
  styleUrls: ['./sign-off-stats.component.css']
})
export class SignOffStatsComponent implements OnInit {

    @Input() employeeState: ApprovalState;

    sof: SignOffStats;

  constructor() { }

  ngOnInit() {
  }

//   ngOnChanges(changes: SimpleChanges) {
//     for (const propName in changes) {
//         if (changes.hasOwnProperty(propName)) {

//         const chng = changes[propName];

//         if (propName === 'employeeState') {
//             this.employeeState = chng.currentValue;
//         }
//     }
// }

// this.sof = this.employeeState.CardStatus.SignOffStats;
// }

}
