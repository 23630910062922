import { Component, OnInit } from '@angular/core';

import { map, take } from 'rxjs/operators';
import { Observable, interval, of } from 'rxjs';

@Component({
  selector: 'app-waiting-for-server',
  templateUrl: './waiting-for-server.component.html',
  styleUrls: ['./waiting-for-server.component.css']
})
export class WaitingForServerComponent implements OnInit {

  constructor() { }
  wakeUp$: Observable<string>;

  wakeUp = [
    'Getting list of employees needing review.',
    'Getting list of employees needing review...',
    'Getting list of employees needing review.....',
    'Getting list of employees needing review.......',
    '',
    'Server are you ready??',
    '',
    'Hello server? You there?',
    'Hello server!',
    '',
    'Not looking good :(',
    'Not looking good :(  :(',
    '',
    'Don\'t blame me. Your the one with the keyboard.',
    '',
    'Took too long! Please contact Scott N.',
  ];

  ngOnInit() {
    this.wakeUp$ = interval(2500).pipe(
      take(this.wakeUp.length),
      map((i) => this.wakeUp[i]),
    );
  }

}
