import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ProjectHoursWorked } from '../data/data.model';
import { ProjectDataService } from '../data/project-data.service';

@Component({
  selector: 'app-project-hours-day-entry',
  templateUrl: './project-hours-day-entry.component.html',
  styleUrls: ['./project-hours-day-entry.component.css']
})
export class ProjectHoursDayEntryComponent implements OnInit {

  @Input() employeeProjectWork: ProjectHoursWorked;

  errorMsg = '';
  localEmployeeProjectWork: ProjectHoursWorked;


  constructor(
    private projectDataService: ProjectDataService
  ) { }


  updateHours(value: number) {

    if (Number.isNaN(value)) {
      this.errorMsg = 'Numbers only. Not saved.';
      return;
    }

    if (value < 0) {
      this.errorMsg = 'Cannot have negative hours. Not saved.';
      return;
    }
    if (value > 24) {
      this.errorMsg = 'Cannot work more then 24 hours in a day. Not saved.';
      return;
    }
    this.errorMsg = '';

    this.localEmployeeProjectWork.Hours = value;
    console.log('hours:', value, this.employeeProjectWork);

    this.updateBackend();
  }

  updateComment(value: string) {
    this.localEmployeeProjectWork.Comments = value;
    console.log('comments local:', value, this.localEmployeeProjectWork);

    this.updateBackend();
  }

  updateBackend() {

    console.log('updateBackend - EmployeeProjectWork', this.localEmployeeProjectWork);

    this.projectDataService.sendProjectHours(this.localEmployeeProjectWork)
      .subscribe(msg => console.log(msg));
  }



  ngOnInit() {
    this.localEmployeeProjectWork = this.employeeProjectWork;
  }

}
