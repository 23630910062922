
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ApprovalState, TimecardWeek, IDayTotalHourlyHours } from '../data/data.model';

@Component({
    selector: 'app-hourly-hours',
    templateUrl: './hourly-hours.component.html',
    styleUrls: ['./hourly-hours.component.css']
})
export class HourlyHoursComponent implements OnInit, OnChanges {
    @Input() timecardWeek: TimecardWeek;
    @Input() employeeState: ApprovalState;

    WeekTotal = 0;

    constructor() {  }


    ngOnInit() {

    }
    // when the @inputs change, fires below. Go through and get latest values and then update status.
    ngOnChanges(changes: SimpleChanges) {
        this.WeekTotal = 0;
    }

    onCalcDayTotal(event: IDayTotalHourlyHours) {
        this.WeekTotal += event.TotalHours;
        console.log('onCalcDayTotal', event);

    }
}
