import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';


import { environment } from '../../environments/environment';
import { TimecardWeek, ProjectHoursWorked, ProjectListing4Person, ServerResponse, ProjectReorder } from './data.model';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable()
export class ProjectDataService {

    constructor(private http: HttpClient) { }

    getProjectsDaySum(timecardDay: Date, employeeID: number): Promise<Number> {

        const datePipe: any = new DatePipe('en-US');
        const tcsDateStr: string = datePipe.transform(timecardDay, 'MM/dd/yyyy'); // 11/10/2016

        return this.http.get(environment.REST_API + 'DailyProjectHours/' + employeeID + '?timecardDay=' + tcsDateStr)
            .toPromise()
            .then(response => response as Number)
            .catch(this.handleError);
    }


    getProjectsWorked(employeeID: Number, timecardWeek: TimecardWeek): Promise<ProjectHoursWorked[]> {

        const datePipe: any = new DatePipe('en-US');
        const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016

        const url: string = environment.REST_API + 'DailyProjectHours/' + employeeID + '?timecardStart='
                + tcsDateStr + '&numDays=' + timecardWeek.NumDays ;

        return this.http.get(url)
            .toPromise()
            .then(response => response as TimecardWeek[])
            .catch(this.handleError);
    }


    getProjectListing(employeeID: Number, timecardWeek: TimecardWeek): Promise<ProjectListing4Person[]> {

        const datePipe: any = new DatePipe('en-US');
        const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016

        const url: string = environment.REST_API + 'ProjectListing/' + employeeID + '?timecardStart='
                + tcsDateStr + '&numDays=' + timecardWeek.NumDays ;

        return this.http.get(url)
            .toPromise()
            .then(response => response as ProjectListing4Person[])
            .catch(this.handleError);
    }

    getProjectListing_Available(employeeID: Number, timecardWeek: TimecardWeek): Promise<ProjectListing4Person[]> {

      const datePipe: any = new DatePipe('en-US');
      const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016

      const url: string = environment.REST_API + 'ProjectsListing_Available/' + employeeID + '?timecardStart='
              + tcsDateStr + '&numDays=' + timecardWeek.NumDays ;

      return this.http.get(url)
          .toPromise()
          .then(response => response as ProjectListing4Person[])
          .catch(this.handleError);
  }

  getProjectListing_Restricted(employeeID: Number, timecardWeek: TimecardWeek): Promise<ProjectListing4Person[]> {

    const datePipe: any = new DatePipe('en-US');
    const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016

    const url: string = environment.REST_API + 'ProjectsListing_Restricted/' + employeeID + '?timecardStart='
            + tcsDateStr + '&numDays=' + timecardWeek.NumDays ;

    return this.http.get(url)
        .toPromise()
        .then(response => response as ProjectListing4Person[])
        .catch(this.handleError);
}


  /** POST: send a single project hours entry to the server. */
  sendProjectHours(projectHours: ProjectHoursWorked): Observable<ServerResponse> {

    console.log('In sendProjectHours', projectHours);

    return this.http.post<ProjectHoursWorked>(
      environment.REST_API + 'DailyProjectHours/', projectHours, httpOptions)
      .pipe(
        tap(eStatus => console.log('sendProjectHours:', eStatus)),
        // tap((eStatus: string) => console.log(`Approval Responce Msg:${eStatus}`)),
        catchError(this.handleError)
      );
  }

  /** POST: send a project reorder to the server. */
  // CALLER ALWAYS NEED TO SUBSCRIBE!
  sendProjectListingOrder(projectReorder: ProjectReorder): Observable<ServerResponse> {

    console.log('In sendProjectListingOrder', projectReorder);

    return this.http.post<ProjectReorder>(
      environment.REST_API + 'ProjectListing_Reorder/', projectReorder, httpOptions)
      .pipe(
        tap(eStatus => console.log('sent sendProjectListingOrder:', eStatus)),
        // tap((eStatus: string) => console.log(`Approval Responce Msg:${eStatus}`)),
        catchError(this.handleError)
      );
  }

//   private handleError<T>(operation = 'operation', result?: T) {
//     return (error: any): Observable<T> => {

//       // TODO: send the error to remote logging infrastructure
//       console.error(error); // log to console instead

//       // TODO: better job of transforming error for user consumption
//       // this.log(`${operation} failed: ${error.message}`);

//       // Let the app keep running by returning an empty result.
//       return of(result as T);
//     };
//   }
    private handleError(error: any): Promise<any> {
        console.error('An error occurred in ProjectDataService:', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
