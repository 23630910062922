import { Component, OnInit, Input } from '@angular/core';
import { TimecardWeek, ApprovalState } from '../data/data.model';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.css']
})
export class SubmitButtonComponent implements OnInit {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;

  disableSubmitButton: boolean;
  constructor() { }

  ngOnInit() {
  this.disableSubmitButton = true;
  }


  onCheckHasErrors(event: boolean) {
    this.disableSubmitButton = event;
    console.log('disableSubmitButton', event);

  }

}
