// Component to provide the timecard period. Allows users to move back and forth through weeks.
// Scott N. 2/8/2017
// My first Angular2 component!!

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TimecardWeekService } from '../data/timecard-week.service';
import { TimecardWeek } from '../data/data.model';
import {IMyOptions, IMyDateModel} from 'mydatepicker';


@Component({
  selector: 'app-timeperiod-picker',
  templateUrl: './timeperiod-picker.component.html',
  styleUrls: ['./timeperiod-picker.component.css']
})
export class TimeperiodPickerComponent implements OnInit {

    @Output() whenTCWeekChange = new EventEmitter<TimecardWeek>();

    timecardWeek: TimecardWeek;
    private tcDate: Date = new Date();
    todaysDate: Date = new Date();

    timeCardStartDisplay: Date;
    timeCardEndDisplay: Date;

    get startDate() {
        return this.timecardWeek.TimeCardStart;
    }
    get endDate() {
        return this.timecardWeek.TimeCardEnd;
    }
    private myDatePickerOptions: IMyOptions = {
        // other options...
        dateFormat: 'mm/dd/yyyy',
        markCurrentDay: true,
        firstDayOfWeek: 'su',
        showInputField: false,
        showClearDateBtn: false,
        inline: false,
        height: '25px',
        width: '2em'
    };
    constructor(
        private timecardWeekService: TimecardWeekService
    ) { }


      getTimecardWeek(): void {
    // heavy lifting of what is correct timecard week occurs on the server.
      const datePipe: any = new DatePipe('en-US');
      const tcDateStr: string = datePipe.transform(this.tcDate, 'yyyy-MM-dd'); // 2016-5-13
      this.timecardWeekService
          .getTimecardWeek(tcDateStr)
          .then(timecardWeek => this.timecardWeek = timecardWeek)
          .then(timecardWeek => this.whenTCWeekChange.emit(timecardWeek));
          // .then(timecardWeek => console.log('then timecardWeek', timecardWeek));
  }



    ngOnInit() {
        const defaultTCweek: Date = new Date();
        defaultTCweek.setDate(defaultTCweek.getDate() - 4); // show last TC week for FSSM

        this.tcDate = defaultTCweek;
      this.getTimecardWeek();
  }

  onDateChanged(event: IMyDateModel) {
      this.tcDate = event.jsdate || new Date();
      this.getTimecardWeek();
      console.log('timecardWeek', this.tcDate);
      // event properties are: event.date, event.jsdate, event.formatted and event.epoc
      // console.log('onDateChanged(): ', event.date, ' - jsdate: ', new Date(event.jsdate).toLocaleDateString(),
      // ' - formatted: ', event.formatted, ' - epoc timestamp: ', event.epoc);
  }

  lastWeek() {
      const tempDate = new Date(this.timecardWeek.TimeCardStart.toString());
     this.tcDate = new Date(tempDate.valueOf() - 86400000); // shift back one day
    this.getTimecardWeek();

    }
  nextWeek() {
      const tempDate = new Date(this.timecardWeek.TimeCardEnd.toString());
      this.tcDate = new Date(tempDate.valueOf() + 1.5 * 86400000); // shift forward one day into next TC week.
      this.getTimecardWeek();
  }



}
