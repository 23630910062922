import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApprovalState, TimecardWeek, ProjectListing4Person, ProjectHoursWorked } from '../data/data.model';
import { ProjectDataService } from '../data/project-data.service';
import { ManageProjectsListComponent } from './manage-projects-list.component';


@Component({
  selector: 'app-project-hours-entry',
  templateUrl: './project-hours-entry.component.html',
  styleUrls: ['./project-hours-entry.component.css']
})
export class ProjectHoursEntryComponent implements OnInit, OnChanges {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;
  projectListing4Person: ProjectListing4Person[];
  projectHours: ProjectHoursWorked[];

  constructor(
    private projectDataService: ProjectDataService,
    private modalService: NgbModal
  ) { }

  getEmployeesProjects(): void {
    console.log('in projects worked get employee work');
    if (this.timecardWeek) {
      this.projectDataService
        .getProjectListing(this.employeeState.EmployeeNumber, this.timecardWeek)
        .then(employeeStatuses => this.projectListing4Person = employeeStatuses);

      console.log('in projects worked: after subscribe service');
    }
  }

  getEmployeesProjectsHours(): void {
    console.log('in projects worked get employee work');
    if (this.timecardWeek) {
      this.projectDataService
        .getProjectsWorked(this.employeeState.EmployeeNumber, this.timecardWeek)
        .then(employeeStatuses => this.projectHours = employeeStatuses);

      console.log('in projects worked: after subscribe service');
    }
  }

  fillDay(cimsProjectNumber: string, tcDate: string): ProjectHoursWorked {
    // pulls existing project work entries for a particular project and day, get the project work detail.
    // passed on to control that will display and handle data updates.
    // if no existing project hours, then fill in the basics - employee number, project number and date.
    let foundProjectHours: ProjectHoursWorked;
    const notFoundProjectHours: ProjectHoursWorked = {
      Date: tcDate,
      ProjectNumber: cimsProjectNumber,
      EmployeeNumber: this.employeeState.EmployeeNumber,
      ProjectName: '',
      ProjectSponser: '',
      Comments: '',
      Hours: null
    };


    if (this.projectHours !== undefined) {
      foundProjectHours = (this.projectHours.filter(
        line => line.ProjectNumber === cimsProjectNumber &&
          line.Date.toString() === tcDate))[0]; // person id is already assured, take the out the one and only object.
    }

    if (foundProjectHours !== undefined) {
      return foundProjectHours;
    } else {
      return notFoundProjectHours;
    }
  }

  ngOnInit() {
  }

  // when the @inputs change, fires below. Go through and get latest values and then update status.
  ngOnChanges(changes: SimpleChanges) {
    this.getEmployeesProjects();
    this.getEmployeesProjectsHours();
  }

  whenTCWeekChange(event: TimecardWeek) {
    this.timecardWeek = event;
    this.getEmployeesProjects();
    this.getEmployeesProjectsHours();
  }

  openMangeProjects() {
    const modalRef = this.modalService.open(ManageProjectsListComponent, {size: 'xl', scrollable: true});
    modalRef.componentInstance.timecardWeek = this.timecardWeek;
    modalRef.componentInstance.employeeState = this.employeeState;

    modalRef.result.then((result) => {
      this.getEmployeesProjects();
      this.getEmployeesProjectsHours();
      });

  }

}
