import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { TimecardDay } from '../data/data.model';
import { ProjectDataService } from '../data/project-data.service';


@Component({
  selector: 'app-project-hours-sum-day',
  template: `<input type="text" Name="tbDate-Day" class="HourlyHourinput ReadOnly"
             value="{{timecardDay.dayOfWeek}} {{timecardDay.date |date:'MM/dd' }}" />
            <input type="text" class="HourlyHourinput ReadOnly"value="{{daysTotalProjectHours | number:'1.1-1'}}" />`,
  // template: '{{daysTotalProjectHours}}'
      styleUrls: ['./hourly-day.component.css']
})
export class ProjectHoursSumDayComponent implements OnInit, OnChanges {
    @Input() timecardDay: TimecardDay;
    @Input() employeeNumber: number;

    @Output() CalcProjectDayTotal = new EventEmitter<Number>();

    daysTotalProjectHours: Number = 1.23456789;

    constructor(private projectDayService: ProjectDataService) { }


    getProjectHours4Date(): void {
        //  console.log('this.emptyHourlyDay:', this.emptyHourlyDay);

        this.projectDayService
            .getProjectsDaySum(this.timecardDay.date, this.employeeNumber)
            .then(day => this.daysTotalProjectHours = day)
            .then(day => { this.CalcProjectDayTotal.emit(this.daysTotalProjectHours); });
         // .then(day => { console.log('Project Hours:', this.daysTotalProjectHours); });

    }

    // when the @inputs change, fires below. Go through and get latest values and then update status.
    ngOnChanges(changes: SimpleChanges) {

        this.getProjectHours4Date();

    }

    ngOnInit() {
  }

}
