import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { TimecardWeek, TimecardErrors } from './data.model';

import { environment } from '../../environments/environment';

@Injectable()
export class TimecardErrorsService {

    constructor(private http: HttpClient) { }

    getTimecardErrors(employeeID: Number, timecardWeek: TimecardWeek): Promise<TimecardErrors> {

        const datePipe: any = new DatePipe('en-US');
        const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016
        //    console.log('tcsDateStr:', tcsDateStr);
        console.log('getTimecardErrors timecardWeek:', timecardWeek); // BusinessRuleChecks/2328?timecardstart=11/1/2016&NumDays=5
        return this.http.get(environment.REST_API + 'BusinessRuleChecks/' + employeeID
                + '?timecardstart=' + tcsDateStr + '&NumDays=' + timecardWeek.NumDays)
            .toPromise()
            .then(response => response as TimecardErrors)
            //  .then(response => { console.log('From getEmployeeStatuss Promise:', response); response.json().data as any[]; })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
