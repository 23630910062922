import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApprovalState, TimecardWeek, ProjectListing4Person, ProjectReorder } from '../data/data.model';
import { ProjectDataService } from '../data/project-data.service';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-manage-projects-list',
  templateUrl: './manage-projects-list.component.html',
  styleUrls: ['./manage-projects-list.component.css']
})
export class ManageProjectsListComponent implements OnInit, OnChanges {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;

  activeProjects: ProjectListing4Person[];
  availableProjects: ProjectListing4Person[];
  restrictedProjects: ProjectListing4Person[];

  constructor(
    private projectDataService: ProjectDataService,
    public activeModal: NgbActiveModal,
    private dragulaService: DragulaService
  ) {

    // this.dragulaService.createGroup('ProjectList', {
    //   // ...
    // });

    this.dragulaService.dropModel('ProjectList').subscribe(args => {
      // Source/Target INDEX is 0 based, need to add 1 for DB.
      // if source is availableProjects and target is showingProjects >> adding to timecard. Use source projectnumber, TargetIndex + 1
      // if source is showingProjects and target is availableProjects >> removing from timecard. Use source projectnumber, Index = -99
      // if source is showingProjects and target is showingProjects >> reordering timecard. Use source projectnumber, Index = TargetIndex+1
      // if source is availableProjects and target is availableProjects >> do nothing
      const source: string = args.source.id;
      const target: string = args.target.id;
      const targetIndex: number = args.targetIndex + 1;
      const cimsProjectNumber: string = args.item.CimsProjectNumber;

      const projectReorder: ProjectReorder = {
        employeeId: this.employeeState.EmployeeNumber,
        projectID: cimsProjectNumber,
        newOrder: targetIndex
      };

      if ((source === 'showingProjects') && (target === 'availableProjects')) {
        projectReorder.newOrder = -99;
      }

      if (!((source === 'availableProjects') && (target === 'availableProjects'))) {
        this.projectDataService.sendProjectListingOrder(projectReorder)
          .subscribe(msg => console.log(msg));  // << ALWAYS NEED SUBSCRIBE!
      }
    });

  }

  getActiveProjects(): void {
    console.log('getting active projects');
    if (this.timecardWeek) {
      this.projectDataService
        .getProjectListing(this.employeeState.EmployeeNumber, this.timecardWeek)
        .then(d => this.activeProjects = d);
    }
  }

  getAvailableProjects(): void {
    console.log('getting availalbe projects');
    if (this.timecardWeek) {
      this.projectDataService
        .getProjectListing_Available(this.employeeState.EmployeeNumber, this.timecardWeek)
        .then(d => this.availableProjects = d);
    }
  }

  getRestrictedProjects(): void {
    console.log('getting restricted projects');
    if (this.timecardWeek) {
      this.projectDataService
        .getProjectListing_Available(this.employeeState.EmployeeNumber, this.timecardWeek)
        .then(d => this.restrictedProjects = d);
    }
  }
  // when the @inputs change, fires below. Go through and get latest values and then update status.
  ngOnChanges(changes: SimpleChanges) {
    this.getActiveProjects();
    this.getAvailableProjects();
    this.getRestrictedProjects();
  }


  ngOnInit() {
    this.getActiveProjects();
    this.getAvailableProjects();
    this.getRestrictedProjects();
  }


}
