import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

import { CurrentUserService } from '../data/current-user.service';
import { TimecardStatusService } from '../data/timecard-status.service';

import { TimecardWeek, ApprovalState, TimeCard2SignOff, ServerResponse } from '../data/data.model';
import { ApproveTimeCardService } from '../data/approve-time-card.service';


@Component({
  selector: 'app-approve-timecard',
  templateUrl: './approve-timecard.component.html',
  styleUrls: ['./approve-timecard.component.css']
})
export class ApproveTimecardComponent implements OnInit, OnChanges {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;

  ApproversName = 'Not Set';
  // public ltimecardWeek: TimecardWeek;
  // public lemployeeState: ApprovalState;
  uspMessage = 'Not set';

  modalMessage: string;
  modalTitle: string;
  modalDisplay = 'none';


  constructor(private currentUserService: CurrentUserService,
    private approveTimeCardService: ApproveTimeCardService,
    private timecardStatusService: TimecardStatusService
  ) { }


  getApproversName(): void {

    this.currentUserService
      .getCurrentUserName()
      .then(name => this.ApproversName = name);
  }


  ngOnChanges(changes: SimpleChanges) {
    this.uspMessage = 'Not set';
  }

  ngOnInit() {
    this.getApproversName();
  }

  approveTimecard() {


    console.log('approveTimecard', this.employeeState);

    if (this.employeeState !== undefined && this.timecardWeek !== undefined) {

      const datePipe: any = new DatePipe('en-US');
      const tcsDateStr: string = datePipe.transform(this.timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016

      const tc2Approve: TimeCard2SignOff = {
        EmployeeNumber: this.employeeState.EmployeeNumber,
        CheckSum: this.employeeState.CardStatus.cardCheckSum,
        TimeCardDays: this.timecardWeek.NumDays,
        TimeCardStartDate: tcsDateStr
      };
      this.approveTimeCardService.sendTimeCard(tc2Approve)
        .subscribe(msg => this.handleApproveal(msg));
    }
  }

  handleApproveal(uspProcessResponce: ServerResponse) {
    this.uspMessage = uspProcessResponce.dbProcessStatus;
    // console.log('got to handleApproval', uspProcessResponce);


    // SQL Server Responses.
    // Update UI to hide 'By clicking...'  & change CSS state in employee list.
    // CompleteTransfer

    // Pop Up warning & reload
    // NotAllowed2Approve - Sorry you are not allowed to approve this person. Please reload the borowser.
    // FailCheckSum - This person’s timecard's hours and projects changed since you loaded this page.
    //                Please reload to get a current set of data. If the problem keeps occurring contact Scott N.
    // AlreadyApproved - This timecard is already approved. It could have been approved by someone else since you loaded this page.
    //                  Please reload to get a current set of data. If the problem keeps occurring contact Scott N.
    // Pop Up contact Scott N.
    // Error occurred trying to update the records on the server. Please contact Scott Nichols
    // with the error message, person & week you tried to approve.
    // FailedUpdateeSignatures
    // FailedInsert_eSignatures
    // FailedInsertHoursTrackingTable

    const reloadBrowser = '<br/><br/>Please reload to get a current set of data. If the problem keeps occurring contact Scott N.';
    const serverError = 'Error occurred trying to update the records on the server. Please contact Scott N.' +
      ' with the error message, person & week you tried to approve.<br/><br/> ';

    switch (uspProcessResponce.dbProcessStatus) {
      case 'CompleteTransfer': {
        // this.employeeState.CardStatus.allowApprove = false;

        this.timecardStatusService.updateEmployeesStatuses(this.timecardWeek,
             this.employeeState.EmployeeNumber, uspProcessResponce.cardStatus);

        break;
      }
      case 'NotAllowed2Approve': {
        this.modalTitle = 'Not allowed to approve';
        this.modalMessage = 'Sorry you are not allowed to approve this person. Please reload the borowser.' + reloadBrowser;
        this.openModal();
        break;
      }
      case 'FailCheckSum': {
        this.modalTitle = 'Failed Checksum';
        this.modalMessage = 'This person’s timecard\'s hours and projects changed since you loaded this page.' + reloadBrowser;
        this.openModal();
        break;
      }
      case 'AlreadyApproved': {
        this.modalTitle = 'Already Approved';
        this.modalMessage = 'This timecard is already approved. It could have been approved' +
          ' by someone else since you loaded this page.' + reloadBrowser;
        this.openModal();
        break;
      }
      case 'FailedUpdateeSignatures': {
        this.modalTitle = 'SQL Server Error - Failed to update signatures';
        this.modalMessage = serverError + 'FailedUpdateeSignatures' + reloadBrowser;
        this.openModal();
        break;
      }
      case 'FailedInsert_eSignatures': {
        this.modalTitle = 'SQL Server Error';
        this.modalMessage = serverError + 'FailedInsert_eSignatures' + reloadBrowser;
        this.openModal();
        break;
      }
      case 'FailedInsertHoursTrackingTable': {
        this.modalTitle = 'SQL Server Error';
        this.modalMessage = serverError + 'FailedInsertHoursTrackingTable' + reloadBrowser;
        this.openModal();
        break;
      }

      default: {
        this.modalTitle = 'Unknown Error';
        this.modalMessage = uspProcessResponce.dbProcessStatus + reloadBrowser;
        this.openModal();
        break;
      }


    }


  }

  openModal() {
    this.modalDisplay = 'block';
  }

  onCloseModal() {
    this.modalDisplay = 'none';
  }

}
