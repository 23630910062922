import { Component, OnInit,  Input } from '@angular/core';
import { TimecardWeek, ApprovalState, TimeCard2SignOff, CardStatus, ServerResponse } from '../data/data.model';
import { CurrentUserService } from '../data/current-user.service';

@Component({
  selector: 'app-approval-block',
  templateUrl: './approval-block.component.html',
  styleUrls: ['./approval-block.component.css']
})
export class ApprovalBlockComponent implements OnInit {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;
  @Input() showTimecardIssues: boolean;
  constructor(private currentUserService: CurrentUserService) { }

  currentUser = 'Not Set';
  ngOnInit() {
    this.getApproversName();
    this.showTimecardIssues = true;

  }

  getApproversName(): void {

    this.currentUserService
      .getCurrentUserName()
      .then(name => this.currentUser = name);
  }

  // Submitted,
  //   SubmittedChangedNotResubmitted
  //   NotSubmitted
  //   Approved
  //           ApprovedChangedByApprover
  //           ApprovedNotSubmitted



}
