import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {ApprovalState, TimecardWeek} from '../data/data.model';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  constructor(private http: HttpClient) { }

  getCurrentUserName(): Promise<string> {

    return this.http.get(environment.REST_API + 'CurrentUser/')
          .toPromise()
          .then(response => response as string)
          .catch(this.handleError);
  }

  getCurrentUserTimecardState(timecardWeek: TimecardWeek): Promise<ApprovalState> {

    const datePipe: any = new DatePipe('en-US');
    const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016
    const url = environment.REST_API + 'UsersTimecardState/?timecardstart=' + tcsDateStr + '&NumDays=' + timecardWeek.NumDays;

    return this.http.get(url)
          .toPromise()
          .then(response => response as ApprovalState)
          .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
      console.error('An error occurred', error); // for demo purposes only
      return Promise.reject(error.message || error);
  }
}
