import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TimecardWeek } from './data.model';
import { environment } from '../../environments/environment';


@Injectable()
export class TimecardWeekService {

    constructor(private http: HttpClient) { }

    getTimecardWeek(dateinWeek: string): Promise<TimecardWeek> {
        return this.http.get(environment.REST_API + 'timecardPeriod/' + dateinWeek)  // 2016-5-13
            .toPromise()
            .then(response => response as TimecardWeek)
            //  .then(response => { console.log('From getEmployeeStatuss Promise:', response); response.json().data as any[]; })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
