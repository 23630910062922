
import { Component, OnInit } from '@angular/core';

import { TimecardStatusService } from '../data/timecard-status.service';
import { ApprovalState, TimecardWeek } from '../data/data.model';

@Component({
    selector: 'app-timecard-approve',
    templateUrl: './timecard-approve.component.html',
    styleUrls: ['./timecard-approve.component.css']
})
export class TimecardApproveComponent implements OnInit {
    employeeStatuses: ApprovalState[];
    timecardWeek: TimecardWeek;
    selectedEmployee: ApprovalState;
    showTimecardIssues: boolean;

    constructor(
        private timecardStatusService: TimecardStatusService
    ) { }

    getEmployeeStatuses(): void {
        if (this.timecardWeek) {
            this.timecardStatusService
                .getAprroversEmployeesStatuses(this.timecardWeek)
                .subscribe(employeeStatuses => this.UpdateGrid(employeeStatuses));

            //            console.log('in timecard states compt get employee status: after subscribe service');
        }
    }


    ngOnInit(): void {
        this.showTimecardIssues = true;
    }

    onSelect(employee: ApprovalState): void {
        this.selectedEmployee = employee;
        console.log('In onselect', employee);
    }

    whenTCWeekChange(event: TimecardWeek) {
        this.timecardWeek = event;
        this.getEmployeeStatuses();

        // this.selectedEmployee = this.employeeStatuses.find(employ)
    }

    // When the list of employee status need to change, update the grid display and if an employee has
    // already been selected, update them too.
    UpdateGrid(approveStates: ApprovalState[]): void {
        this.employeeStatuses = approveStates;
        if (this.selectedEmployee !== undefined) {

            const eNumber = this.selectedEmployee.EmployeeNumber;

            this.selectedEmployee = approveStates.find(e => e.EmployeeNumber === eNumber);

        }
    }
}
