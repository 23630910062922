import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TimecardStatusService } from '../data/timecard-status.service';
import { ApprovalState, TimecardWeek } from '../data/data.model';
import {CurrentUserService} from '../data/current-user.service';
import {ApprovalBlockComponent} from '../shared/approval-block.component';
@Component({
  selector: 'app-timecard-entry',
  templateUrl: './timecard-entry.component.html',
  styleUrls: ['./timecard-entry.component.css']
})
export class TimecardEntryComponent implements OnInit {
  // @Input() timecardWeek: TimecardWeek;
  // @Input() employeeState: ApprovalState;
  // @Input() showTimecardIssues: boolean;

  timecardWeek: TimecardWeek;
  selectedEmployee: ApprovalState;
  showTimecardIssues: boolean;

  constructor(
    private currentUserService: CurrentUserService,
    private modalService: NgbModal) { }


    getCurrentUserTimecardState(): void {
    if (this.timecardWeek) {
      this.currentUserService
        .getCurrentUserTimecardState(this.timecardWeek)
        .then(response => this.selectedEmployee = response);

      //            console.log('in timecard states compt get employee status: after subscribe service');
    }
  }

  ngOnInit() {
    this.showTimecardIssues = false;
  }
  onSelect(employee: ApprovalState): void {
    this.selectedEmployee = employee;
    console.log('In onselect', employee);
  }

  whenTCWeekChange(event: TimecardWeek) {
    this.timecardWeek = event;
    this.getCurrentUserTimecardState();

    // this.selectedEmployee = this.employeeStatuses.find(employ)
  }
  cmdReviewAndSubmit(): void {

      const modalRef = this.modalService.open(ApprovalBlockComponent, {size: 'xl', scrollable: true});
      modalRef.componentInstance.timecardWeek = this.timecardWeek;
      modalRef.componentInstance.employeeState = this.selectedEmployee;

      modalRef.result.then((result) => {
        // this.getEmployeesProjects();
        // this.getEmployeesProjectsHours();
        });


  }

}
