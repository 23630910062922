import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ApprovalState, TimecardWeek, ProjectHoursWorked } from '../data/data.model';

import {ProjectDataService} from '../data/project-data.service';


@Component({
  selector: 'app-projects-worked',
  templateUrl: './projects-worked.component.html',
  styleUrls: ['./projects-worked.component.css']
})
export class ProjectsWorkedComponent implements OnInit, OnChanges {
  @Input() timecardWeek: TimecardWeek;
  @Input() employeeState: ApprovalState;
   projectHours: ProjectHoursWorked[];

  constructor(
    private projectDayService: ProjectDataService
  ) { }

  getEmployeesProjects(): void {
    console.log('in projects worked get employee work');
    if (this.timecardWeek) {
        this.projectDayService
        .getProjectsWorked(this.employeeState.EmployeeNumber, this.timecardWeek)
            .then(employeeStatuses => this.projectHours = employeeStatuses);

            console.log('in projects worked: after subscribe service');
        }
}

  ngOnInit() {
  }

// when the @inputs change, fires below. Go through and get latest values and then update status.
ngOnChanges(changes: SimpleChanges) {
  this.getEmployeesProjects();
}

whenTCWeekChange(event: TimecardWeek) {
      this.timecardWeek = event;
      this.getEmployeesProjects();
  }

}
