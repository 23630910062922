import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DatePipe } from '@angular/common';

import { ApprovalState, TimecardWeek, CardStatus } from './data.model';

import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';



@Injectable()
export class TimecardStatusService {
  constructor(private http: HttpClient) { }


  approvalStatusList: ApprovalState[];
  currentTimecardWeek: TimecardWeek;

  getAprroversEmployeesStatuses(timecardWeek: TimecardWeek): Observable<ApprovalState[]> {

    if (this.approvalStatusList !== undefined && this.currentTimecardWeek === timecardWeek) {

      return of(this.approvalStatusList);

    } else {
      const datePipe: any = new DatePipe('en-US');
      const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016
      const url = environment.REST_API + 'ApprovalList/?timecardstart=' + tcsDateStr + '&NumDays=' + timecardWeek.NumDays;

      this.currentTimecardWeek = timecardWeek;

      return this.http.get<ApprovalState[]>(url)
        .pipe(
          map(aList => this.approvalStatusList = aList),
          tap(eStatus => this.log(`got getEmployeeStatuses`)),
          catchError(this.handleError('getEmployeeStatuses', []))
        );
    }
  }

  updateEmployeesStatuses(timecardWeek: TimecardWeek, employeeNumber: number, cardStatus: CardStatus) {
    if (this.currentTimecardWeek === timecardWeek) {
      const itemIndex = this.approvalStatusList.findIndex(e => e.EmployeeNumber === employeeNumber);
      this.approvalStatusList[itemIndex].CardStatus = cardStatus;
    }
  }


  // getAprroversEmployeesStatuses (timecardWeek: TimecardWeek): Observable<ApprovalState[]> {

  //   const datePipe: any = new DatePipe('en-US');
  //   const tcsDateStr: string = datePipe.transform(timecardWeek.TimeCardStart, 'MM/dd/yyyy'); // 11/10/2016
  //   const url = environment.REST_API + 'ApprovalList/?timecardstart=' + tcsDateStr + '&NumDays=' + timecardWeek.NumDays;

  // const tempArray: Observable<ApprovalState[]> = approvalStatusList.pipe(mergeMap( o => o ));

  //   return tempArray;
  //   // return this.http.get<ApprovalState[]>(url)
  //   //   .pipe(
  //   //     tap(eStatus => this.log(`got getEmployeeStatuses`)),
  //   //     catchError(this.handleError('getEmployeeStatuses', []))
  //   //   );
  // }



  /** Log a message with the MessageService */
  private log(message: string) {
    console.log('timecard-status service: ' + message);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  // private handleError(error: any): Promise<any> {
  //     console.error('An error occurred', error); // for demo purposes only
  //     return Promise.reject(error.message || error);
  // }
}
