import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { TimecardDay, IHourlyDay, IDayTotalHourlyHours } from '../data/data.model';
import { HourlyDayService } from '../data/hourly-day.service';

@Component({
  selector: 'app-hourly-day',
  templateUrl: './hourly-day.component.html',
  styleUrls: ['./hourly-day.component.css']
})
export class HourlyDayComponent implements OnInit, OnChanges {

    @Input() timecardDay: TimecardDay;
    @Input() employeeNumber: number;

    @Output() CalcDayTotal = new EventEmitter<IDayTotalHourlyHours>();

    hourlyDay: IHourlyDay;  // ..= new HourlyDay;
    emptyHourlyDay: IHourlyDay;

    daysTotalHours: number;

    private ltimecardDay: TimecardDay;
    private lemployeeNumber: number;


    constructor(
        private hourlyDayService: HourlyDayService
    ) { }


    getHourlyHours4Date(): void {
      //  console.log('this.emptyHourlyDay:', this.emptyHourlyDay);

        this.hourlyDayService
            .getHourlyDay(this.timecardDay.date, this.employeeNumber)
            .then(day => this.hourlyDay = day)
            .then(day => { if (day === null) {this.hourlyDay = this.emptyHourlyDay; } })
            .then(day => { this.calculateTotalHours(this.hourlyDay); });
            // .then(day => { console.log('Hours:', this.hourlyDay); });


    }

    // when the @inputs change, fires below. Go through and get latest values and then update status.
    ngOnChanges(changes: SimpleChanges) {

        this.getHourlyHours4Date();

    }


    ngOnInit() {
        this.emptyHourlyDay = {
            EmployeeNumber: this.employeeNumber,
            Date: this.timecardDay.date,
            In1: null,
            Out1: null,
            In2: null,
            Out2: null,
            In3: null,
            Out3: null,
            OtherHours: null,
            OtherCode: null,
            OtherHours2: null,
            OtherCode2: null

        };

       // this.hourlyDay = new HourlyDay();
      //  this.getHourlyHours4Date(); // Commented out 7/6/2018 causing double add of weekly total.
    }

    calculateTotalHours(hourlyDetail: IHourlyDay) {
       let TotalHours = 0;

       TotalHours = this.calcTimeSpan(hourlyDetail.In1, hourlyDetail.Out1);
       TotalHours += this.calcTimeSpan(hourlyDetail.In2, hourlyDetail.Out2);
       TotalHours += this.calcTimeSpan(hourlyDetail.In3, hourlyDetail.Out3);


       if (hourlyDetail.OtherCode != null) {
           if (hourlyDetail.OtherHours != null) {
               TotalHours += hourlyDetail.OtherHours;
           }
        }
       if (hourlyDetail.OtherCode2 != null) {
           if (hourlyDetail.OtherHours2 != null) {
                   TotalHours = + hourlyDetail.OtherHours2 + 2;
           }
        }
       if (TotalHours > 0.05) {
           const daysTotalHours: IDayTotalHourlyHours = { Day: this.timecardDay.dayOfWeek, TotalHours: TotalHours };
        //   console.log('calculateTotalHours:', daysTotalHours);
           this.CalcDayTotal.emit(daysTotalHours); // this.timecardDay.dayOfWeek + TotalHours.toString());
           // this.onCalcDayTotal.emit(TotalHours.toString());//this.timecardDay.dayOfWeek);
       }


       this.daysTotalHours = TotalHours;
    }


    calcTimeSpan(InTime: any, OutTime: any): number {
        let InOut: any = 1;

        // Check first set of times
        if (OutTime != null) {
            if (InTime != null) {
                if (InTime < OutTime) {
                    const In: number = new Date(InTime.toString()).valueOf();
                    const Out: number = new Date(OutTime.toString()).valueOf();
                    InOut = Out - In;

                }
            }
        }
        return  (InOut / (1000 * 3600));


    }



}
