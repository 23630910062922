import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { DragulaModule } from 'ng2-dragula';

import { AppComponent } from './app.component';

import { HeaderComponent } from './nav/header.component';
import { FooterComponent } from './nav/footer.component';
import { TimecardApproveComponent } from './dashboard/timecard-approve.component';
import { TimecardEntryComponent } from './dashboard/timecard-entry.component';

import { EmployeeStatusComponent } from './timecard/employee-status.component';
import { HourlyHoursComponent } from './timecard/hourly-hours.component';
import { HourlyDayComponent } from './timecard/hourly-day.component';
import {ProjectHoursSumComponent } from './timecard/project-hours-sum.component';
import {ProjectHoursSumDayComponent } from './timecard/project-hours-sum-day.component';

import { TimecardStatusService } from './data/timecard-status.service';
import { TimecardWeekService } from './data/timecard-week.service';
import { TimecardErrorsService } from './data/timecard-errors.service';
import { HourlyDayService } from './data/hourly-day.service';
import { ProjectDataService } from './data/project-data.service';


import { MyDatePickerModule } from 'mydatepicker'; // https://github.com/kekeh/mydatepicker
import { TimeperiodPickerComponent } from './shared/timeperiod-picker.component';
import { ProjectsWorkedComponent } from './timecard/projects-worked.component';
import { ApproveTimecardComponent } from './timecard/approve-timecard.component';
import { SignOffStatsComponent } from './timecard/sign-off-stats.component';
import { WaitingForServerComponent } from './nav/waiting-for-server.component';
import { ProjectHoursEntryComponent } from './timecard/project-hours-entry.component';
import { ProjectHoursDayEntryComponent } from './timecard/project-hours-day-entry.component';
import { ProjectDetailsComponent } from './shared/project-details.component';
import { ManageProjectsListComponent } from './timecard/manage-projects-list.component';
import { ApprovalBlockComponent } from './shared/approval-block.component';
import { SubmitButtonComponent } from './timecard/submit-button.component';


const routes: Routes = [
  { path: '', redirectTo: '/entry', pathMatch: 'full' },
  { path: 'dashboard', component: TimecardApproveComponent },
  { path: 'entry', component: TimecardEntryComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    TimecardApproveComponent,
    TimeperiodPickerComponent,
    EmployeeStatusComponent,
    HourlyHoursComponent,
    HourlyDayComponent,
    ProjectHoursSumComponent,
    ProjectHoursSumDayComponent,
    ProjectsWorkedComponent,
    ApproveTimecardComponent,
    SignOffStatsComponent,
    WaitingForServerComponent,
     TimecardEntryComponent,
     ProjectHoursEntryComponent,
     ProjectHoursDayEntryComponent,
     ProjectDetailsComponent,
     ManageProjectsListComponent,
     ApprovalBlockComponent,
     SubmitButtonComponent
  ],

  imports: [
    BrowserModule,
    // FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
      MyDatePickerModule,
      NgbModule,
      DragulaModule.forRoot()
  ],

  providers: [
    TimecardStatusService,
    TimecardWeekService,
    TimecardErrorsService,
    HourlyDayService,
    ProjectDataService
  ],

  bootstrap: [AppComponent],
  entryComponents: [ManageProjectsListComponent, ApprovalBlockComponent],
  exports: [ManageProjectsListComponent, ApprovalBlockComponent]
})


export class AppModule { }


